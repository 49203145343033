<script>
import { required, email, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
// import axios from "axios";
import axios from "@/config/axios";

import appConfig from "@/../app.config";
import Layout from "@/layouts/main.vue";

import { BASE_URL } from "../helpers/constants";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  page: {
    title: "Connexion",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      submitted: false,
      authError: null,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations: {
    user: {
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      password: {
        required: helpers.withMessage("Password is required", required),
      },
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
  methods: {
    async signinapi() {
      this.submitted = true;

      this.v$.$touch();

      if (this.v$.$invalid) return;

      const result = await axios.post(BASE_URL + "/auth/login", {
        email: this.user.email,
        password: this.user.password,
      });

      this.user.password = "";
      this.submitted = false;

      if (result.data.error) {
        this.isRegisterError = true;

        return (this.authError = result.data.message);
      }

      localStorage.setItem("jwt", result.data.token);

      this.$router.push({
        path: this.$route.query.redirectFrom ?? "/",
      });
    },
  },
};
</script>

<template>
  <Layout>
    <div class="auth-page-wrapper">
      <div class="auth-page-content pt-4">
        <b-container>
          <b-row class="justify-content-center">
            <b-col md="8" lg="6" xl="5">
              <b-card no-body class="mt-4">
                <b-card-body class="p-4">
                  <div class="text-center mt-2">
                    <h5 class="text-primary">Connexion</h5>
                    <p class="text-muted">
                      Connectez-vous pour gérer l'envoie des codes
                    </p>
                  </div>
                  <div class="p-2 mt-4">
                    <form class="needs-validation" @submit.prevent="signinapi">
                      <b-alert
                        v-model="registerSuccess"
                        class="mt-3"
                        variant="success"
                        dismissible
                        >Login successfull.</b-alert
                      >

                      <b-alert
                        v-model="isRegisterError"
                        class="mt-3"
                        variant="danger"
                        dismissible
                        >{{ authError }}
                      </b-alert>

                      <div
                        v-if="notification.message"
                        :class="'alert ' + notification.type"
                      >
                        {{ notification.message }}
                      </div>
                      <div class="mb-3">
                        <label for="useremail" class="form-label"
                          >Email <span class="text-danger">*</span></label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="user.email"
                          id="useremail"
                          :class="{
                            'is-invalid': submitted && v$.user.email.$error,
                          }"
                          placeholder="Enter email address"
                        />
                        <div
                          v-for="(item, index) in v$.user.email.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span v-if="item.$message">{{ item.$message }}</span>
                        </div>
                      </div>

                      <div class="mb-2">
                        <label for="userpassword" class="form-label">
                          Password <span class="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="user.password"
                          :class="{
                            'is-invalid': submitted && v$.user.password.$error,
                          }"
                          id="userpassword"
                          placeholder="Enter password"
                        />
                        <div
                          v-if="submitted && v$.user.password.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="v$.user.password.required.$message">{{
                            v$.user.password.required.$message
                          }}</span>
                        </div>
                      </div>

                      <div class="mt-4">
                        <b-button variant="success" class="w-100" type="submit"
                          >Se connecter</b-button
                        >
                      </div>

                      <div class="mt-4 text-center">
                        <div class="signin-other-title">
                          <h5 class="fs-13 mb-4 title text-muted">@GICAM</h5>
                        </div>
                      </div>
                    </form>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>

      <footer class="d-none footer">
        <b-container>
          <b-row>
            <b-col lg="12">
              <div class="text-center">
                <p class="mb-0 text-muted">
                  &copy; {{ new Date().getFullYear() }} Velzon. Crafted with
                  <i class="mdi mdi-heart text-danger"></i> by Themesbrand
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </footer>
    </div>
  </Layout>
</template>
